import React from "react";
import cn from "classnames";
import * as styleConstants from "@/constants/style";
import PageTitle from "@/components/kit/page-title";
import ClientOnly from "@/utils/ClientOnly";
import Map from "./Map";
import { graphql } from "gatsby";
import * as css from "./contacts.modules.scss";

const Delay = ({ children }) => {
  const [canMount, setCanMount] = React.useState(false);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setCanMount(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return canMount ? children : null;
};

const Contacts = ({ data }) => {
  const { layoutContacts } = data;

  return (
    <div className="mb-28 desktop:pt-10 desktop1280:pt-12 desktop1440:pt-14">
      <PageTitle>{layoutContacts.title}</PageTitle>

      <div
        className={cn(
          styleConstants.container,
          "py-6 desktop:py-7 tablet:flex"
        )}
      >
        <div
          className={
            "tablet:order-3 tablet:flex-1 min-w-0 tablet:flex flex-col"
          }
        >
          <div
            className={cn(
              css.map,
              "-mx-4 tablet:mx-0 tablet:order-2 tablet:mt-6"
            )}
          >
            <ClientOnly>
              <Delay>
                <Map location={layoutContacts.location} />
              </Delay>
            </ClientOnly>
          </div>

          <div
            className={cn(
              css.address,
              "py-7 text-center text-title-2 uppercase border-b-2 border-solid " +
                "tablet:py-0 tablet:order-1 tablet:text-left tablet:border-0"
            )}
            dangerouslySetInnerHTML={{
              __html: layoutContacts.text.childMarkdownRemark.html,
            }}
          />
        </div>

        <div className={"desktop:w-1/24 desktop:order-2"} />

        <div
          className={
            "py-7 tablet:py-0 tablet:order-1 tablet:w-1/3 desktop:w-1/4"
          }
        >
          <div
            className={cn(
              css.aside,
              "text-normal text-center tablet:text-left tablet:w-7/8 desktop:w-full"
            )}
            dangerouslySetInnerHTML={{
              __html: layoutContacts.textAside.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contacts;

export const contactsPageFragment = graphql`
  fragment ContactsPageFragment on ContentfulLayoutContacts {
    title
    location {
      lat
      lon
    }
    text {
      childMarkdownRemark {
        html
      }
    }
    textAside {
      childMarkdownRemark {
        html
      }
    }
    ogDescription {
      ogDescription
    }
    ogImage {
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
  }
`;
