import React from "react";
import { YMaps, Map, Placemark, ZoomControl } from "react-yandex-maps";
import useMedia from "@/utils/useMedia";

const Contacts = ({ location }) => {
  const zoomConfig = useMedia(
    ["(min-width: 768px)"],
    [
      {
        size: "small",

        position: {
          top: 20,
          left: "auto",
          right: 20,
          bottom: "auto",
        },
      },
    ],
    {
      size: "small",

      position: {
        top: "auto",
        left: "auto",
        right: 20,
        bottom: 20,
      },
    }
  );

  const placeMarkImage = useMedia(
    ["(min-width: 1024px)"],
    [
      {
        iconImageSize: [112, 112],
        iconImageOffset: [-66, -66],
      },
    ],
    {
      iconImageSize: [60, 60],
      iconImageOffset: [-30, -30],
    }
  );

  return (
    <YMaps query={{ load: "package.full" }}>
      <Map
        width="100%"
        height="100%"
        defaultState={{
          center: [location.lat, location.lon],
          zoom: 16,
          controls: [],
        }}
      >
        <ZoomControl options={zoomConfig} />

        <Placemark
          defaultGeometry={[location.lat, location.lon]}
          options={{
            iconLayout: "default#image",
            iconImageHref: "/images/placemark.svg",
            ...placeMarkImage,
          }}
        />
      </Map>
    </YMaps>
  );
};

export default Contacts;
